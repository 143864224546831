import React from 'react';
import { SuccessIcon } from '../icons/SuccessIcon';
import { WarningIcon } from '../icons/WarningIcon';
import { ErrorIcon } from '../icons/ErrorIcon';
import { RemoveIcon } from '../icons/RemoveIcon';
import { DownloadIcon } from '../icons/DownloadIcon';
import { PauseIcon } from '../icons/PauseIcon';
import { DeleteIcon } from '../icons/DeleteIcon';
import { ChatIcon } from '../icons/ChatIcon';
import { PlayCircleIcon } from '../icons/PlayCircleIcon';

export const StatusIconSuccess = () => {
  return (
    <div className="bg-secondary-lime p-3 rounded-full">
      <SuccessIcon />
    </div>
  );
};

export const StatusIconWarning = () => {
  return (
    <div className="bg-secondary-orange p-3 rounded-full">
      <WarningIcon />
    </div>
  );
};

export const StatusIconPause = () => {
  return (
    <div className="bg-secondary-orange p-3 rounded-full">
      <PauseIcon />
    </div>
  );
};

export const StatusIconCritical = () => {
  return (
    <div className="bg-interaction-error p-3 rounded-full">
      <ErrorIcon />
    </div>
  );
};

export const StatusIconDelete = () => {
  return (
    <div className="bg-interaction-error p-3 rounded-full">
      <DeleteIcon />
    </div>
  );
};

export const StatusIconRemove = () => {
  return (
    <div className="bg-interaction-error p-3 rounded-full">
      <RemoveIcon />
    </div>
  );
};

export const StatusIconImage = ({ leadingProp }: { leadingProp: string }) => {
  return (
    <div className="bg-white w-12 h-12 rounded-full">
      <img src={leadingProp} alt="" />
    </div>
  );
};

export const StatusIconDownload = () => {
  return (
    <div className="bg-secondary-lime p-3 rounded-full">
      <DownloadIcon />
    </div>
  );
};

export const StatusIconChat = () => {
  return (
    <div className="bg-secondary-lime p-3 rounded-full">
      <ChatIcon />
    </div>
  );
};

export const StatusIconResume = () => {
  return (
    <div className="bg-secondary-lime p-3 rounded-full">
      <PlayCircleIcon />
    </div>
  );
};
