import { SVGProps } from 'react';

import React, { forwardRef } from 'react';

interface Props extends SVGProps<SVGSVGElement> {
  color?: string;
  size?: string | number;
  isFill?: boolean;
}

export const RemoveIcon = forwardRef(
  (
    { color = 'currentColor', size = 24, isFill = false, ...rest }: Props,
    ref: any,
  ) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill={color}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        ref={ref}
      >
        <mask
          id="mask0_1049_4726"
          style={{
            maskType: 'alpha',
          }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="24"
          height="24"
        >
          <rect width="24" height="24" fill={color} />
        </mask>
        <g mask="url(#mask0_1049_4726)">
          <path
            d="M3 12C3 16.97 7.03 21 12 21C16.97 21 21 16.97 21 12C21 7.03 16.97 3 12 3C7.03 3 3 7.03 3 12ZM12 19.5C7.86 19.5 4.5 16.14 4.5 12C4.5 7.86 7.86 4.5 12 4.5C16.14 4.5 19.5 7.86 19.5 12C19.5 16.14 16.14 19.5 12 19.5ZM7.5 12.75V11.25H16.5V12.75H7.5Z"
            fill={color}
          />
        </g>
      </svg>
    );
  },
);

RemoveIcon.displayName = 'RemoveIcon';
