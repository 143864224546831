import { SVGProps } from 'react';

import React, { forwardRef } from 'react';

interface Props extends SVGProps<SVGSVGElement> {
  color?: string;
  size?: string | number;
  isFill?: boolean;
}

export const ChatIcon = forwardRef(
  (
    { color = 'currentColor', size = 24, isFill = false, ...rest }: Props,
    ref: any,
  ) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill={color}
      >
        <mask
          id="mask0_1349_107884"
          style={{
            maskType: 'alpha',
          }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="24"
          height="24"
        >
          <rect width="24" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_1349_107884)">
          <path d="M7.5 13.5H12V15H7.5V13.5Z" fill="black" />
          <path d="M16.5 7.5H7.5V9H16.5V7.5Z" fill="black" />
          <path d="M7.5 10.5H16.5V12H7.5V10.5Z" fill="black" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.5 4.5H4.5V19.5H6L6.67 18.85C7.23 18.3 7.98 18 8.76 18H19.5V4.5ZM18 15C18 15.83 17.33 16.5 16.5 16.5H7.5C6.67 16.5 6 15.83 6 15V7.5C6 6.67 6.67 6 7.5 6H16.5C17.33 6 18 6.67 18 7.5V15Z"
            fill={color}
          />
        </g>
      </svg>
    );
  },
);

ChatIcon.displayName = 'ChatIcon';
