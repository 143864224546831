import { SVGProps } from 'react';

import React, { forwardRef } from 'react';

interface Props extends SVGProps<SVGSVGElement> {
  color?: string;
  size?: string | number;
  isFill?: boolean;
}

export const DeleteIcon = forwardRef(
  (
    { color = 'currentColor', size = 24, isFill = false, ...rest }: Props,
    ref: any,
  ) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill={color}
      >
        <mask
          id="mask0_1299_3029"
          style={{
            maskType: 'alpha',
          }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="24"
          height="24"
        >
          <rect width="24" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_1299_3029)">
          <path
            d="M19.834 3.74V5.24C19.004 5.24 18.334 5.91 18.334 6.74V16.5C18.334 18.99 16.314 21.01 13.824 21.01H9.34398C6.85398 21.01 4.83398 18.99 4.83398 16.5V6.74C4.83398 5.91 4.16398 5.24 3.33398 5.24V3.74H8.58398V3H14.584V3.74H19.834ZM16.814 16.5V8.24C16.814 6.58 15.474 5.24 13.814 5.24H9.33398C7.67398 5.24 6.33398 6.58 6.33398 8.24V16.5C6.33398 18.16 7.67398 19.5 9.33398 19.5H13.814C15.474 19.5 16.814 18.16 16.814 16.5ZM12.704 7.87V16.89H14.214V7.87H12.704ZM8.95398 16.89H10.464V7.87H8.95398V16.89ZM12.704 7.87V16.89H14.214V7.87H12.704ZM8.95398 16.89H10.464V7.87H8.95398V16.89ZM12.704 7.87V16.89H14.214V7.87H12.704ZM8.95398 7.87V16.89H10.464V7.87H8.95398Z"
            fill={color}
          />
        </g>
      </svg>
    );
  },
);

DeleteIcon.displayName = 'DeleteIcon';
