import { SVGProps } from 'react';

import React, { forwardRef } from 'react';

interface Props extends SVGProps<SVGSVGElement> {
  color?: string;
  size?: string | number;
  isFill?: boolean;
}

export const PauseIcon = forwardRef(
  (
    { color = 'currentColor', size = 24, isFill = false, ...rest }: Props,
    ref: any,
  ) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill={color}
      >
        <mask
          id="mask0_1299_20732"
          style={{
            maskType: 'alpha',
          }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="24"
          height="24"
        >
          <rect width="24" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_1299_20732)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.66602 6H10.916V18.75H5.66602V6ZM7.17602 16.14C7.17602 16.76 7.67602 17.26 8.29602 17.26C8.91602 17.26 9.41602 16.76 9.41602 16.14V8.64C9.41602 8.02 8.91602 7.52 8.29602 7.52C7.67602 7.52 7.17602 8.02 7.17602 8.64V16.14Z"
            fill={color}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.416 6H17.666V18.75H12.416V6ZM13.926 16.14C13.926 16.76 14.426 17.26 15.046 17.26C15.666 17.26 16.166 16.76 16.166 16.14V8.64C16.166 8.02 15.666 7.52 15.046 7.52C14.426 7.52 13.926 8.02 13.926 8.64V16.14Z"
            fill={color}
          />
        </g>
      </svg>
    );
  },
);

PauseIcon.displayName = 'PauseIcon';
