import { SVGProps } from 'react';

import React, { forwardRef } from 'react';

interface Props extends SVGProps<SVGSVGElement> {
  color?: string;
  size?: string | number;
  isFill?: boolean;
}

export const SuccessIcon = forwardRef(
  (
    { color = 'currentColor', size = 24, isFill = false, ...rest }: Props,
    ref: any,
  ) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 25 24"
        fill={color}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        ref={ref}
      >
        <mask
          id="mask0_6366_1163"
          style={{
            maskType: 'alpha',
          }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="25"
          height="24"
        >
          <rect x="0.666656" width="24" height="24" fill={color} />
        </mask>
        <g mask="url(#mask0_6366_1163)">
          <path
            d="M3.66666 12C3.66666 16.97 7.69666 21 12.6667 21C17.6367 21 21.6667 16.97 21.6667 12C21.6667 7.03 17.6367 3 12.6667 3C7.69666 3 3.66666 7.03 3.66666 12ZM12.6667 19.5C8.52666 19.5 5.16666 16.14 5.16666 12C5.16666 7.86 8.52666 4.5 12.6667 4.5C16.8067 4.5 20.1667 7.86 20.1667 12C20.1667 16.14 16.8067 19.5 12.6667 19.5ZM17.4267 9.62L11.5967 15.45L7.88666 11.74L8.95666 10.67L9.48666 11.2C10.6567 12.37 12.5567 12.37 13.7267 11.2L16.3767 8.55L17.4467 9.62H17.4267Z"
            fill={color}
          />
        </g>
      </svg>
    );
  },
);

SuccessIcon.displayName = 'SuccessIcon';
